import Field from '@/components/Page/Liff/Form/Field'

export default {
  components: { Field },
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
    help: {
      type: String,
      default: '',
    },
    helpClass: {
      type: String,
      default: 'g-text-secondary',
    },
    disabled: {
      type: Boolean,
    },
    required: {
      type: Boolean,
    },
    readonly: {
      type: Boolean,
    },
  },
}
