<template>
  <form @submit.prevent="submit">
    <slot :busy="busy" />
  </form>
</template>

<script>
  export default {
    inject: ['$alert'],
    props: {
      action: {
        type: Function,
        required: true,
      },
      afterAction: {
        type: Function,
        default: () => {}
      },
    },
    data: () => ({
      busy: false,
    }),
    methods: {
      async submit() {
        if (this.busy) {
          return
        }

        this.busy = true

        try {
          await this.action()
        } catch ({ message }) {
          this.busy = false

          return this.$alert(message)
        }

        this.afterAction()
        this.busy = false
      },
      setBusy(bool) {
        this.busy = bool;
      },
    },
  }
</script>
