<template>
  <div class="s-form-field">
    <label class="s-block s-text-grak-dark" :for="name">{{ label }}</label>
    <slot />
    <p class="s-text-sm s-mt-1" :class="error ? 's-text-danger' : helpClass">{{ error || help || '&nbsp;' }}</p>
  </div>
</template>

<script>
  export default {
    props: {
      name: {
        type: String,
        required: true,
      },
      label: {
        type: String,
        required: true,
      },
      error: {
        type: String,
        default: '',
      },
      help: {
        type: String,
        default: '',
      },
      helpClass: {
        type: String,
        default: 's-text-secondary',
      },
    },
  }
</script>
