<template>
  <div>
    <PageTitle>請輸入會員密碼</PageTitle>
    <Form :action="submit" :after-action="afterSubmit" v-slot="{ busy }">
      <div class="s-space-y-3 s-mb-10">
        <Input
          label="輸入新密碼"
          type="password"
          name="password"
          placeholder="6 - 10 個英文加數字，需區分大小寫"
          maxlength="10"
          v-model="password"
          required
        />
        <Input
          label="再次輸入新密碼"
          type="password"
          name="confirmation"
          placeholder="6 - 10 個英文加數字，需區分大小寫"
          maxlength="10"
          v-model="confirmation"
          required
        />
      </div>
      <button class="s-btn s-btn-primary s-mb-6" type="submit" :disabled="!formIsValid || busy">
        確定
      </button>
      <router-link
        class="s-btn s-btn-secondary"
        :to="{ name: 'LiffGroupBuyingAuthLogin', query: { mobile: mobileNumber } }"
      >
        回登入頁
      </router-link>
    </Form>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import PageTitle from '@/components/Page/Liff/GroupBuying/PageTitle'
  import Input from '@/components/Page/Liff/Form/Input'
  import Form from '@/components/Page/Liff/Form/Form'

  export default {
    components: { PageTitle, Input, Form },
    inject: ['$user'],
    data: () => ({
      password: '',
      confirmation: '',
    }),
    computed: {
      mobileNumber () {
        return this.$route.query.mobile || this.$user().mobile_phone
      },
      passwordIsValid () {
        return /[0-9a-z]{6,10}/i.test(this.password)
      },
      confirmationIsValid () {
        return this.password === this.confirmation
      },
      formIsValid () {
        return this.passwordIsValid && this.confirmationIsValid
      },
    },
    methods: {
      ...mapActions({
        updatePassword: 'liffGroupBuying/updatePassword',
      }),
      async submit () {
        await this.updatePassword({ mobileNumber: this.mobileNumber, password: this.password })
      },
      afterSubmit () {
        this.$router.push({
          name: 'LiffGroupBuyingAuthPasswordChanged',
          query: {
            mobile: this.mobileNumber,
          },
        })
      },
    },
  }
</script>
